import { Box, BoxProps, Flex, FlexProps } from "@givenwell/components";
import { createLink, Link } from "@tanstack/react-router";
import { ForwardedRef, forwardRef } from "react";
import { Button, ButtonProps } from "./Button";
import { IconButton, IconButtonProps } from "./IconButton";

export const BoxLink = createLink(
  forwardRef(function BoxLink(props: BoxProps, ref: ForwardedRef<HTMLAnchorElement>) {
    return <Box as={Link as any} ref={ref} {...props} />;
  }),
);

export const ButtonLink = createLink(
  forwardRef(function ButtonLink(props: ButtonProps, ref: ForwardedRef<HTMLAnchorElement>) {
    return (
      <Button
        as={Link as any}
        // @ts-expect-error polymorphic prop
        ref={ref}
        {...props}
      />
    );
  }),
);

export const IconButtonLink = createLink(
  forwardRef(function IconButtonLink(props: IconButtonProps, ref: ForwardedRef<HTMLAnchorElement>) {
    return (
      <IconButton
        as={Link as any}
        // @ts-expect-error polymorphic prop
        ref={ref}
        {...props}
      />
    );
  }),
);

export const FlexLink = createLink(
  forwardRef(function FlexLink(props: FlexProps, ref: ForwardedRef<HTMLAnchorElement>) {
    return <Flex as={Link as any} ref={ref} {...props} />;
  }),
);
