import { Box, Center, Flex, FlexProps, Text } from "@givenwell/components";
import { colors } from "@givenwell/theme";
import { createLink, CreateLinkProps, Link } from "@tanstack/react-router";
import { ComponentPropsWithoutRef, ForwardedRef, forwardRef, ReactNode } from "react";

export function BottomNav(props: FlexProps) {
  return (
    <Flex
      as="footer"
      {...props}
      css={{
        h: "calc(64px + max(var(--safe-area-inset-bottom), 12px) - 12px)",
        bg: "#fff",
        width: "100%",
        boxShadow: "0 -2px 4px rgba(0,0,0, .04)",
        zIndex: 1000,
        userSelect: "none",
        pl: "var(--safe-area-inset-left)",
        pr: "var(--safe-area-inset-right)",

        ...props.css,
        "@lg": {
          display: "none",
        },
      }}
    />
  );
}

type BottomNavItemProps = ComponentPropsWithoutRef<"a"> &
  FlexProps & {
    end?: boolean;
    className?: string;
    label: string;
    icon: ReactNode;
    activeIcon?: ReactNode;
    disabled?: boolean;
    badge?: ReactNode;
  };
export const BottomNavItem = createLink(
  forwardRef(function BottomNavItem(
    { icon, activeIcon, label, badge, ...props }: CreateLinkProps & BottomNavItemProps,
    ref: ForwardedRef<HTMLAnchorElement>,
  ) {
    return (
      <Flex
        as={props.to && props.to !== "." ? Link : "button"}
        css={{
          height: 64,
          flexDir: "column",
          justify: "center",
          items: "center",
          position: "relative",
          rounded: 16,
          flex: "1 0 0px",

          "&[disabled]": {
            opacity: 0.5,
          },
        }}
        {...(props as any)}
        className={props.to ? props.className : ""}
        ref={ref}
      >
        <Center
          css={{
            color: colors.gray500,
            ".active > &": {
              color: colors.blue800,
            },
            position: "relative",
            size: 24,
            d: "flex",
            items: "center",
            justify: "center",
          }}
        >
          <Box
            css={{
              d: "block",
              ".active > * > &": {
                d: "none",
              },
            }}
          >
            {icon}
          </Box>
          <Box
            css={{
              d: "none",
              ".active > * > &": {
                d: "block",
              },
            }}
          >
            {activeIcon || icon}
          </Box>
          {badge}
        </Center>

        <Text
          css={{
            color: colors.gray500,
            weight: 400,
            ".active > &": {
              color: colors.blue800,
              weight: 600,
            },
            fontSize: 10,
            lineHeight: "16px",
            whiteSpace: "nowrap",
            "@xs": {
              d: "none",
            },
          }}
        >
          {label}
        </Text>
      </Flex>
    );
  }),
);
