import { getErrorMessage } from "@/utils/error";
import { config } from "@common/utils/config";
import { toast } from "@givenwell/components";
import { createAuth } from "@givenwell/fusionauth";
import {
  AddressService,
  ApiError,
  AuthService,
  DirectInvitationService,
  HomeService,
  InvitationService,
  ListingSearchService,
  ListingsService,
  MeService,
  MessagesService,
  MyNotificationsService,
  OpenAPI,
  PurchasesService,
  RequestService,
  ResourcesService,
  SubscriptionsService,
  SuppliersService,
  TransactionsService,
} from "@givenwell/marketplace-api";
import { QueryCache, QueryClient } from "@tanstack/react-query";

export const auth = createAuth({
  serverUrl: config.apiBaseUrl,
  redirectUri: config.marketplaceUrl,
  logoutUri: config.marketplaceUrl + "/sign-in",
  environmentName: config.environmentName,
  applicationName: "marketplace",
});

let refetchTokenStatus: "running" | "idle" = "idle";

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError(error) {
      if (error instanceof ApiError && error.status === 401) {
        if (refetchTokenStatus === "running") {
          return;
        }
        refetchTokenStatus = "running";
        auth
          .refresh(true)
          .then(success => {
            if (success) {
              setTimeout(() => {
                api.auth
                  .getMarketplaceUserInfo()
                  .then(() => {
                    queryClient.refetchQueries();
                  })
                  .catch(() => {
                    auth.logout();
                  });
              }, 100);
            } else {
              auth.logout();
            }
          })
          .finally(() => {
            // 30 sec grace period from attempting to refresh token before allowing another attempt.
            setTimeout(() => {
              refetchTokenStatus = "idle";
            }, 30 * 1000);
          });
      }
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: query => {
        if (query.state.status === "error") {
          return false;
        }
        return query.isStale();
      },
      refetchOnMount: true,
      refetchOnReconnect: true,
      retry: false,
      staleTime: Infinity,
    },
    mutations: {
      onSuccess() {
        queryClient.invalidateQueries();
      },
      onError(error) {
        const errorMessage = getErrorMessage(error);
        toast.error("Something went wrong", {
          description: errorMessage,
        });
      },
    },
  },
});

OpenAPI.BASE = config.apiBaseUrl;
OpenAPI.TOKEN = auth.getAccessToken;

export const api = {
  address: AddressService,
  auth: AuthService,
  directInvitation: DirectInvitationService,
  home: HomeService,
  invitation: InvitationService,
  listings: ListingsService,
  listingSearch: ListingSearchService,
  me: MeService,
  myNotifications: MyNotificationsService,
  messages: MessagesService,
  purchases: PurchasesService,
  resources: ResourcesService,
  subscriptions: SubscriptionsService,
  suppliers: SuppliersService,
  transactions: TransactionsService,
  request: RequestService,
};

export type API = typeof api;
